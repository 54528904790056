import React from 'react';

import './assets/css/animate.css';
import './assets/css/animated-gradient.css';
import './assets/css/bootstrap-theme.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/font-roboto.css';
import './assets/css/owl.carousel.css';
import './assets/css/owl.transitions.css';
import './assets/css/prettify.css';
import './assets/css/styles.css';
import './assets/css/theme.css';
import './assets/css/themify-icons.css';
import './assets/css/image-loader.css';

import Nav from './components/Nav';
import Home from './components/Home';
import Legal from './components/gdpr/Legal';
import Footer from './components/Footer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
    return (
        <Router>
            <div className="App" >
                {/* Loader */}
                <div id="page-loader"></div>
                <Nav />


                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/legal" exact component={Legal} />
                </Switch>
                <Footer />
            </div>
        </Router>
    );
}

export default App;    