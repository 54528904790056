import React from 'react';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import './GdprConsentPopup.css';
import {withAlert} from 'react-alert'

Modal.setAppElement('#root');

class GdprConsentPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show : false
        };
        this.closeModal = this.closeModal.bind(this);
        this.closeModalOnAccept = this.closeModalOnAccept.bind(this);
        this.closeModalOnRefuse = this.closeModalOnRefuse.bind(this);
    }

    closeModal() {
        const {alert} = this.props;
        // this.setState({ modalIsOpen: false });
        // alert.show('You need to accept or refuse the privacy policy!');
        alert.show('Enjoy the experience', {
            timeout: 2500, // custom timeout just for this one alert
            type: 'success',
            onOpen: () => {
                console.log('hey')
            }, // callback that will be executed after this alert open
            onClose: () => {
                console.log('closed')
            } // callback that will be executed after this alert is removed
        })

    }

    closeModalOnAccept() {
        Cookies.set('cookieconsent', 'true');
        this.setState({show: false});
        this.closeModal()
    }

    closeModalOnRefuse() {
        window.open("about:blank", "_self");
        window.close();
    }

    render() {
        // this.closeModal();
        // checking to see if we already have the consent and we don't display
        let formClassName = "consent-background";
        if (Cookies.get('cookieconsent') ==='true' || window.location.href.substring(window.location.href.lastIndexOf("/")) == '/legal') {
            formClassName = "consent-background hidden";
        }
        return (
            <div className="consent-sticky">
                <div className={formClassName}>
                    <h2 ref={subtitle => this.subtitle = subtitle}><p className="consent-title"> We value your
                        privacy </p></h2>
                    <div>We use cookies to better understand your needs, improve performance and provide you with
                        personalised content and advertisements. To allow us to provide a better and more tailored
                        experience, please click the "ACCEPT" Button.
                    </div>
                    <div>
                        <a itemProp="url" href="/legal" className="consent-link">Privacy Policy</a>
                    </div>
                    <div className="consent-centerPopup-button-area">
                        <button className="consent-default-button" type="button" role="button"
                                onClick={this.closeModalOnAccept}>I ACCEPT
                        </button>

                        <button className="consent-disagree-button" onClick={this.closeModalOnRefuse}>I DO NOT ACCEPT
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAlert()(GdprConsentPopup);