import React from 'react';


export default class Legal extends React.Component {
    render() {
        return (
            <div>
                <div className="consent-background">
                    <h2 ref={subtitle => this.subtitle = subtitle}><p class="consent-title"> We value your privacy </p></h2>
                    <div>We use cookies to better understand your needs, improve performance and provide you with personalised content and advertisements. To allow us to provide a better and more tailored experience, please click the "OK" Button. <a itemprop="url" href="/legal" className="consent-link">Privacy Policy</a></div>
                </div>
            </div>
        );
    }
}