import React, { Component } from "react";
import LazyLoad from 'react-lazy-load';
import ImageLoader from './ImageLoader.js'
import ContactForm from './ContactForm.js';
import BackgroundImageLoader from './BackgroundImageLoader.js';
import ReactDOM from 'react-dom';
import GdprConsentPopup from "./gdpr/GdprConsentPopup";

class Home extends Component {
  showContactForm = () => {
    this.contactForm.showModal();
	}

  render() {
    return (
      <div className="Home">
        {/* Page Wrapper */}
        <div id="page-wrapper" data-scroll-easing="easeOutBack">

          {/* Section - Home */}
          <section id="home" className="section fullheight bg-blue-dark dark padding-v-100">

            {/* BG Slideshow */}
            <div className="bg-slideshow slideshow" data-auto-play="4000">
              <div className="bg-image animated zooming infinite">
                <LazyLoad debounce={false} offsetVertical={500}>
                  <BackgroundImageLoader src={require("../assets/img/backgrounds/creative1.webp")} alt="" />
                </LazyLoad>
              </div>
              <div className="bg-image animated zooming infinite">
                <LazyLoad debounce={false} offsetVertical={500}>
                  <BackgroundImageLoader src={require("../assets/img/backgrounds/creative2.webp")} alt="" />
                </LazyLoad>
              </div>
            </div>

            <div className="container v-center">
              <h1 className="margin-b-20"><strong>WLUDIO</strong> <br /> Software Consulting Services</h1>
              {/* Roll Down */}
              <span data-target="local-scroll"><span className="roll-down"></span></span>
            </div>
            <GdprConsentPopup />
          </section>
          {/* Section - Home / End */}

          {/* Section - About */}
          <section id="about" className="section fullheight bg-gradient dark padding-v-100" data-header-change="true">

            <div className="section-content container v-center">
              <div className="row">
                <div className="col-lg-8">
                  <h1>We are the <strong>specialists</strong> who love to work with design &amp; development freaks.</h1>
                </div>
              </div>
              {/* Carousel */}
              <div className="carousel pagination-center animated" data-items="3" data-items-desktop="[1199,3]" data-items-tablet="[979,2]" data-items-mobile="[400,1]" data-animation="fadeInUp" >
                <div className="icon-box">
                  <div className="icon icon-lg icon-square icon-green"><i className="ti-cloud"></i></div>
                  <h3>Big Data & <strong>Perfomance</strong> <br /> experience in improvments</h3>
                </div>
                <div className="icon-box">
                  <div className="icon icon-lg icon-square icon-green"><i className="ti-check"></i></div>
                  <h3><strong>+15</strong> Finished projects</h3>
                </div>
                <div className="icon-box">
                  <div className="icon icon-lg icon-square icon-green"><i className="ti-wand"></i></div>
                  <h3><strong>+7 years</strong> experienced experts in <br /> Java & Web Solutions</h3>
                </div>
                <div className="icon-box">
                  <div className="icon icon-lg icon-square icon-green"><i className="ti-heart"></i></div>
                  <h3><strong>+10</strong> Happy clients</h3>
                </div>
                <div className="icon-box">
                  <div className="icon icon-lg icon-square icon-green"><i className="ti-time"></i></div>
                  <h3><strong>+720</strong> Days without coffee</h3>
                </div>
              </div>
            </div>

          </section>
          {/* Section - About / End */}

          {/* Section - Double */}
          <section id="why-us" className="section section-double left fullheight bg-blue-dark dark" data-header-change="true">

            <div className="col-md-6 image">
              <div className="bg-image">
                <LazyLoad debounce={false} offsetVertical={500}>
                  <BackgroundImageLoader src={require("../assets/img/backgrounds/creative3.webp")} alt="" />
                </LazyLoad>
              </div>
            </div>
            <div className="col-md-6 content margin-b-50">
              <h1>We are <strong>the best</strong> solution for you and for your business!</h1>
              <ul className="list-check padding-l-0">
                <li className="animated" data-animation="fadeInRight">Smart Search Solutions with <strong> Elasticsearch</strong></li>
                <li className="animated" data-animation="fadeInRight"><strong>Big Data</strong> long-term experience</li>
                <li className="animated" data-animation="fadeInRight" data-animation-delay="100">Java Solutions</li>
                <li className="animated" data-animation="fadeInRight" data-animation-delay="160">React Frontend</li>
                <li className="animated" data-animation="fadeInRight" data-animation-delay="200">State of the Art Microservice Arhitectures</li>
                <li className="animated" data-animation="fadeInRight" data-animation-delay="240">Online Trainings</li>
                <li className="animated" data-animation="fadeInRight" data-animation-delay="300">CI/CD Pipelines with Jenkins/Github & Docker</li>
              </ul>
            </div>

          </section>
          {/* Section - Double / End */}

          {/* Section - Services */}
          <section id="services" className="section fullheight bg-gradient dark padding-v-100" data-header-change="true">

            <div className="section-content container v-center">
              <div className="row">
                <div className="col-lg-8">
                  <h1 className="margin-b-60">We provide the <strong>best quailty</strong> services - check what we can do for you!</h1>
                </div>
              </div>
              {/* Services */}
              <div className="row">
                {/* Service Box */}
                <div className="col-lg-2 col-md-4 col-sm-6 service-box animated" data-animation="fadeInUp">
                  <div className="icon icon-sm"><i className="ti-desktop"></i></div>
                  <h3><strong>Web</strong> development</h3>
                  <ul className="list-check padding-l-0">
                    <li>Websites in REACT</li>
                    <li>Node JS</li>
                    <li>Javascript, Jquery etc.</li>
                    <li>SEO</li>
                    <li>Social Media Integration</li>
                  </ul>
                </div>
                {/* Service Box */}
                <div className="col-lg-2 col-md-4 col-sm-6 service-box animated" data-animation="fadeInUp" data-animation-delay="100">
                  <div className="icon icon-sm"><i className="ti-comments"></i></div>
                  <h3><strong>Trainings</strong></h3>
                  <ul className="list-check padding-l-0">
                    <li>Java</li>
                    <li>Spring Framework</li>
                    <li>Spring Boot</li>
                    <li>Elasticsearch</li>
                    <li>React</li>
                  </ul>
                </div>
                {/* Service Box */}
                <div className="col-lg-2 col-md-4 col-sm-6 service-box animated" data-animation="fadeInUp" data-animation-delay="200">
                  <div className="icon icon-sm"><i className="ti-server"></i></div>
                  <h3><strong>Database</strong> experts</h3>
                  <ul className="list-check padding-l-0">
                    <li>Postgres, MySql, Oracle</li>
                    <li>MongoDB</li>
                    <li>Elasticsearch</li>
                  </ul>
                </div>
                {/* Service Box */}
                <div className="col-lg-2 col-md-4 col-sm-6 service-box animated" data-animation="fadeInUp" data-animation-delay="300">
                  <div className="icon icon-sm"><i className="ti-mobile"></i></div>
                  <h3><strong>Mobile</strong> & <strong> Desktop</strong> apps</h3>
                  <ul className="list-check padding-l-0">
                    <li>Ios</li>
                    <li>Android</li>
                    <li>Java desktop applications for macOS, Windows, and Linux.</li>
                    <li>Java Backend solutions</li>
                  </ul>
                </div>
                {/* Service Box */}
                <div className="col-lg-2 col-md-4 col-sm-6 service-box animated" data-animation="fadeInUp" data-animation-delay="400">
                  <div className="icon icon-sm"><i className="ti-shopping-cart"></i></div>
                  <h3><strong>CMS</strong> solutions</h3>
                  <ul className="list-check padding-l-0">
                    <li>Java CMS using Magnolia</li>
                    <li>Sugar CMS</li>
                  </ul>
                </div>
                {/* Service Box */}
                <div className="col-lg-2 col-md-4 col-sm-6 service-box animated" data-animation="fadeInUp" data-animation-delay="500">
                  <div className="icon icon-sm"><i className="ti-support"></i></div>
                  <h3><strong>CI/CD</strong> pipeline development</h3>
                  <ul className="list-check padding-l-0">
                    <li>Jenkins</li>
                    <li>Nexus</li>
                    <li>Docker</li>
                  </ul>
                </div>
              </div>
            </div>

          </section>
          {/* Section - Services / End */}

          {/* Section - goal  */}
          <section id="Goal" className="section fullheight bg-blue-dark dark padding-v-100">

            {/* BG Image */}
            <div className="bg-image">
              <LazyLoad debounce={false} offsetVertical={500}>
                <BackgroundImageLoader src={require("../assets/img/backgrounds/creative2.webp")} alt="" />
              </LazyLoad>
            </div>

            <div className="section-content container text-center v-center">

              <div className="row">
                <div className="col-lg-8 col-lg-offset-2">
                  <h1 className="margin-b-50">Connecting pleople through software is our <strong>passion</strong> and our <strong>job</strong>!</h1>
                </div>
              </div>

            </div>

          </section>
          {/* Section - Goal / End */}

          {/* Section - Default */}
          <section id="trainings" className="section fullheight bg-gradient dark padding-v-100" data-header-change="true">

            <div className="section-content container v-center">
              <div className="row v-center-items">
                <div className="col-md-6 margin-b-40">
                  <h1> Software Consulting & Trainings</h1>
                  <p className="lead">With <strong>deep experience</strong> in software engineering, we are able to support our customers by performing a complete analysis of their requirements and their current systems, and create a new plan that will be cost effective while still including wisely considered software solutions.</p>
                  <p>For more details regarding our experience and services please <a href="/services" className="link-default"><span>click here!</span></a></p>
                  <p className="lead">We also hold on site trainings for: Java, Spring, Elasticsearch, React just so you can be sure that your team will have rock solid knowledge!</p>
                </div>
                <div className="col-md-6">
                  <div className="gallery-item">
                    <div className="item-photo">
                      <LazyLoad debounce={false} offsetVertical={500}>
                        <ImageLoader src={require("../assets/img/photos/creative4.webp")} alt="" />
                      </LazyLoad>
                    </div>
                    <div className="item-container">
                      <div className="item-content text-center">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
          {/* Section - Default / End */}


          {/* Section - Team */}
          <section id="team" className="section fullheight bg-blue-dark dark padding-v-100" data-header-change="true">

            <div className="section-content container v-center">
              <div className="row">
                <div className="col-lg-8">
                  <h1 className="margin-b-60">Our team is a group of really <strong>awesome</strong> people! You’ve got to meet them!</h1>
                </div>
              </div>
              <div className="row text-center">
                {/* Member */}
                <div className="col-sm-3 col-xs-6">
                  <div className="member-box margin-b-30">
                    <div className="member-photo margin-b-10">
                      <LazyLoad debounce={false} offsetVertical={500}>
                        <ImageLoader className="square" src={require("../assets/img/members/member01.webp")} alt="" />
                      </LazyLoad>
                    </div>
                    <h6 className="margin-b-0">Vladucu Voican</h6>
                    <strong className="text-sm text-white">Head Of Development</strong>
                  </div>
                </div>

                {/* Icon Box */}
                <div className="col-sm-3 col-sm-offset-0 col-xs-6 col-xs-offset-3">
                  <div className="icon-box margin-b-30 animated" data-animation="bounceIn">
                    <span data-target="local-scroll"><a href="#contact" className="icon icon-square icon-hover icon-red animated-hover" data-hover-animation="shake" data-hover-animation-duration="600"><i className="fa ti-plus"></i></a></span>
                    <h6 className="margin-b-0">Do you want to join Us?</h6>
                  </div>
                </div>
              </div>
            </div>

          </section>
          {/* Section - Team / End */}

          {/* Section - Contact */}
          <section id="contact" className="section fullheight bg-gradient dark padding-v-100" data-header-change="true">

            <div className="section-content container v-center">
              <div className="row">
                <div className="col-lg-8">
                  <h1 className="margin-b-60">Find a way to <strong>get in touch</strong> with Us! I will response <strong>as soon as possible</strong>! </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <span className="icon icon-sm icon-square icon-blue-dark"><i className="fa fa-map-marker"></i></span>
                  <address>
                    <strong>Wludio, SRL.</strong><br />
                    Str. B.P. Hasdeu Nr. 42<br />
                    Craiova, Romania<br />
                  </address>
                </div>
                <div className="col-md-3 col-sm-6">
                  <a href="#" className="icon icon-sm icon-square icon-blue-dark icon-hover animated" data-animation="bounceIn" data-animation-delay="100" data-toggle="modal" onClick={this.showContactForm.bind(this)}><i className="fa fa-envelope"></i></a>
                  <div className="margin-b-30">
                    <strong>E-mail:</strong><br />
                    <a href="/#" className="link-default">office@wludio.com</a>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <span className="icon icon-sm icon-square icon-blue-dark"><i className="fa fa-phone"></i></span>
                  <div className="margin-b-30">
                    <strong>Phone</strong><br />
                    +4(0746) 946033
                  </div>
                </div>
              </div>

            </div>

          </section>
          {/* Section - Contact / End */}
        </div>
        {/* Page Wrapper / End */}

        {/* Ajax Wrapper */}
        <div id="ajax-modal"></div>
        {/* Ajax Loader */}
        <div id="ajax-loader"><i className="fa fa-circle-o-notch fa-spin"></i></div>

        {/* Modal - Contact Form */}
        <div id="contactFormModal">
          <ContactForm ref={contactForm => this.contactForm = contactForm}/>
        </div>
       
        
        {/* Scroll Progress */}
        <div id="scroll-progress" className="bg-white"></div>
      </div>
    );
  }
}
export default Home;
