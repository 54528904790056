import React from 'react';

function Footer() {
  return (
    <div className="Footer">
      <footer id="footer" className="bg-blue-dark padding-v-40 dark text-center">
        <div className="section-content copyright margin-b-20">
          Copyright <strong>WLUDIO SRL</strong> 2020©. CUI: 37770580, Reg. Com. J16/1615/2017 <br />
          <i>What is done in love is done well...</i>
        </div>
        <span data-target="local-scroll"><a href="/#home" className="back-to-top"><i className="fa fa-chevron-up"></i></a></span>
      </footer>
    </div>
  );
}
export default Footer;
