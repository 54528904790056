import React, {Component} from 'react';
import Recaptcha from 'react-recaptcha'
import ReactDOM from 'react-dom';
import {withAlert} from "react-alert";

// import Toastr from '../utils/Toastr';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sender: '',
            subject: '',
            message: '',
            mailSent: false,
            error: null,
            show: false,
            isVerified: false,
            fields: {},
            errors: {}
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.closeContactModal = this.closeContactModal.bind(this);
        // this.handleValidation = this.handleValidation.bind(this);
        this.contactForm = React.createRef();
    }

    // handleValidation() {
    //     let fields = this.state.fields;
    //     let errors = {};
    //     let formIsValid = true;
    //
    //     //Name
    //     if (!fields["name"]) {
    //         formIsValid = false;
    //         errors["name"] = "Cannot be empty";
    //     }
    //
    //     if (typeof fields["name"] !== "undefined") {
    //         if (!fields["name"].match(/^[a-zA-Z]+$/)) {
    //             formIsValid = false;
    //             errors["name"] = "Only letters";
    //         }
    //     }
    //
    //     //Email
    //     if (!fields["email"]) {
    //         formIsValid = false;
    //         errors["email"] = "Cannot be empty";
    //     }
    //
    //     if (typeof fields["email"] !== "undefined") {
    //         let lastAtPos = fields["email"].lastIndexOf('@');
    //         let lastDotPos = fields["email"].lastIndexOf('.');
    //
    //         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
    //             formIsValid = false;
    //             errors["email"] = "Email is not valid";
    //         }
    //     }
    //
    //     //Message
    //     if (!fields["message"]) {
    //         formIsValid = false;
    //         errors["message"] = "Cannot be empty";
    //     } else if (fields["message"].length < 20) {
    //         formIsValid = false;
    //         errors["message"] = "Should contain at least 20 characters";
    //     }
    //
    //     this.setState({errors: errors});
    //     return formIsValid;
    // }
    //
    // contactSubmit(e) {
    //     e.preventDefault();
    //
    //     if (this.handleValidation()) {
    //         alert("Form submitted");
    //     } else {
    //         alert("Form has errors.")
    //     }
    //
    // }
    //
    // handleChange(field, e) {
    //     let fields = this.state.fields;
    //     fields[field] = e.target.value;
    //     this.setState({fields});
    // }

    handleFormSubmit = (event) => {
        event.preventDefault();
        if (this.state.isVerified) {
            // Toastr.success("You have successfully sent the email!");
            this.contactForm.dispatchEvent(new Event('submit'))
            setTimeout(function() { //Start the timer
                this.closeContactModal();
            }.bind(this), 500)
        }
    }

    showModal = () => {
        this.setState({show: true});
    }

    hideModal = () => {
        this.setState({show: false});
    }

    closeContactModal = () => {
        this.hideModal();
    }

    recaptchaLoaded() {
    }

    verifyCallback(response) {
        if (response) {
            this.setState({
                isVerified: true
            })
        }
    }

    componentDidMount() {
        ReactDOM.findDOMNode(this).addEventListener('closeContactModal', this.closeContactModal);
    }

    closeModal() {
        const {alert} = this.props;
        // this.setState({ modalIsOpen: false });
        // alert.show('Testtetetetet!');
        alert.show('Some message', {
            timeout: 2000, // custom timeout just for this one alert
            type: 'success',
            onOpen: () => {
                console.log('hey')
            }, // callback that will be executed after this alert open
            onClose: () => {
                console.log('closed')
            } // callback that will be executed after this alert is removed
        })

    }
    render() {
        let formClassName = this.state.show === true ? "modal show modal-blue-dark" : "modal fade modal-blue-dark";
        return (
            <div className={formClassName} id="contactModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-intro bg-orange">

                        <div className="row padding-t-10 padding-h-20">
                            <button type="button" className="btn icon icon-sm pull-right" style={{paddingRight: "10%"}}
                                    onClick={this.hideModal}>
                                <i className="fa fa-close" style={{color: "white"}}></i>
                            </button>
                        </div>

                        <div className="row padding-h-20">
                            <h1 style={{textAlign: "center"}}>
                                Don't hesitate to send a message!
                            </h1>
                        </div>
                    </div>
                    <div className="modal-content">
                        <div className="modal-body">
                            <form id="contact-form" ref={(ref) => {
                                this.contactForm = ref;
                            }}
                                  //onSubmit={this.contactSubmit.bind(this)}
                            >
                                <fieldset>
                                    <div id="form-alert"></div>
                                    <div id="form-error" className="alert alert-danger">
                                        <ul></ul>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label>Name:</label>
                                            <input ref="name" id="name" name="name" type="text"
                                                   value={this.state.fields["name"]}
                                                   required onChange={this.handleChange}
                                                   className="form-control"/>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Addres e-mail:</label>
                                            <input ref="email" id="email" name="email" type="text"
                                                   value={this.state.fields["email"]}
                                                   required onChange={this.handleChange}
                                                   className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Message:</label>
                                        <textarea ref="message" id="message" name="message"
                                                  value={this.state.fields["message"]}
                                                  required onChange={this.handleChange}
                                                  className="form-control" rows="4"></textarea>
                                    </div>
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-orange btn-filled"
                                            onClick={this.handleFormSubmit}
                                        >
                                            <span>submit</span></button>
                                    </div>
                                    <Recaptcha
                                        sitekey="6LeiDq4aAAAAAPDSoVp3vkzt3XTRndI8nZJDQSW7"
                                        render="explicit"
                                        onloadCallback={this.recaptchaLoaded}
                                        verifyCallback={this.verifyCallback}
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAlert()(ContactForm);