import React from 'react';


function Nav() {

  return (
    <nav>
      {/* Header */}
      <header id="header" className="header-horizontal dark">

        {/* Logo */}
        <a href="/" className="logo pull-left margin-r-40" style={{maxWidth:'6%',maxHeight:'6%'}}><img src={require("../assets/img/logo-wludio.png")} alt="" /></a>

        {/* Navigation */}
        <nav id="main-menu">
          <ul className="nav nav-main pull-left">
            <li>
              <a href="/#home"><span>Home page</span></a>
            </li>
            <li>
              <a href="/#about"><span>About</span></a>
            </li>
            <li>
              <a href="/#why-us"><span>Why Us?</span></a>
            </li>
            <li>
              <a href="/#services"><span>Services</span></a>
            </li>
            <li>
              <a href="/#trainings"><span>Trainings</span></a>
            </li>
            <li>
              <a href="/#team"><span>Team</span></a>
            </li>
            <li>
              <a href="/#contact"><span>Contact</span></a>
            </li>
          </ul>
        </nav>

        {/* Menu Trigger */}
        <a href="/#" className="pull-right margin-l-30" data-target="mobile-nav"><i className="fa fa-bars"></i></a>

        {/* Social Icons */}
        <ul className="social-icons pull-right margin-b-0 hidden-xs">
          <li><a href="/#" className="icon icon-xs"><i className="fa fa-facebook"></i></a></li>
          <li><a href="/#" className="icon icon-xs"><i className="fa fa-twitter"></i></a></li>
        </ul>

      </header>
      {/* Header / End */}

      {/* Mobile Navigation */}
      <nav id="mobile-nav" className="bg-blue-dark dark">
        <div className="mobile-nav-wrapper bg-blue-dark">
          <nav>
            <ul className="nav nav-side">
              <li>
                <a href="#home"><span>Home page</span></a>
              </li>
              <li>
                <a href="#about"><span>About</span></a>
              </li>
              <li>
                <a href="#why-us"><span>Why Us?</span></a>
              </li>
              <li>
                <a href="#services"><span>Services</span></a>
              </li>
              <li>
                <a href="#trainings"><span>Trainings</span></a>
              </li>
              <li>
                <a href="#team"><span>Team</span></a>
              </li>
              <li>
                <a href="#contact"><span>Contact</span></a>
              </li>
            </ul>
          </nav>
        </div>
        <a href="/#" className="mobile-nav-close" data-target="mobile-nav"><i className="fa fa-times" style={{ fontSize: 20 }}></i></a>
      </nav>

    </nav>
  );
}

export default Nav;
