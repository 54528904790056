import React, { Component } from "react";

const _loaded = {};

class ImageLoader extends Component {

    //initial state: image loaded stage 
    state = {
        loaded: _loaded[this.props.src]
    };

    //define our loading and loaded image classes
    static defaultProps = {
        className: "",
        loadingClassName: "img-loading",
        loadedClassName: "img-loaded",
        spinnerClassName: "spinner"
    };

    //image onLoad handler to update state to loaded
    onLoad = () => {
        _loaded[this.props.src] = true;
        this.setState(() => ({ loaded: true }));
    };


    render() {

        let { className, loadedClassName, loadingClassName, spinnerClassName, ...props } = this.props;

        className = `${className} ${this.state.loaded ? loadedClassName : loadingClassName}`;
        spinnerClassName = `${this.state.loaded ? 'no-class' : spinnerClassName}`;

        return <div className={spinnerClassName}> <img src={this.props.src} onClick={this.props.onClick} className={className} onLoad={this.onLoad} alt="" /> </div>;
    }
}

export default ImageLoader;